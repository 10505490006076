
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 3px solid $color-grey-light;
  box-shadow: 0 0 38px 0 rgba(55, 74, 82, 0.18);
  &.ActiveHeader {
    height: 100%;
    overflow-y: auto;
  }
  @include mq($breakpoint-desktop) {
    box-shadow: none;
    border-bottom: none;
    background-color: white;
    &.ActiveHeader {
      height: auto;
    }
  }
}
.NavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 8px;
  background-color: white;
  @include mq($breakpoint-tablet) {
    max-width: 1280px;
    margin: 0 auto;
  }
  @include mq($breakpoint-desktop) {
    border-bottom: none;
    padding: 16px 32px;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 16px;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
    padding: 16px 0;
  }
}
.LeftNavBar {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.MenuButton {
  @include mq($breakpoint-desktop) {
    display: none;
  }
}

.Logo {
  max-width: 134px;
  display: block;
  @include mq($breakpoint-desktop) {
    max-width: 250px;
    img {
      transform: scale(1.15);
      transform-origin: left;
    }
  }
}
.MenuContainerWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  @include mq($breakpoint-desktop) {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 32px;

  }
  @include mq($breakpoint-desktopMedium) {
    padding: 0 16px;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
    padding: 0;
  }
}
.MenuContainer {
  border-bottom: 3px solid $color-grey-light;
  background-color: white;
  padding: 0 16px;

  &.Mobile {
    right: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 100;
    @include mq($breakpoint-desktop) {
      display: none;
    }
  }
  &.Desktop {
    display: none;

    @include mq($breakpoint-desktop) {
      width: 100%;
      display: flex;
      column-gap: 20px;
      padding: 0;
      border-bottom: none;
    }
    @include mq($breakpoint-desktopMedium) {
      column-gap: 40px;
    }
    @include mq($breakpoint-desktopLarge) {
      column-gap: 65px;

    }
  }
}

.NavItem {
  border-top: 1px solid $color-grey-light;
  padding: 8px 0;
  color: black;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.96px;
  text-transform: capitalize;
  display: block;
  width: 100%;
  text-align: left;
  transition: .1s ease-in-out;
  &:hover {
    text-decoration: underline;
    color: $color-primary-teal;
  }
  @include mq($breakpoint-desktop) {
    border-top: none;
    font-size: 18px;
    width: auto;
    &.ActiveNavItem {
      color: $color-primary-teal;
      font-weight: 700;
      span {
        border-bottom: 2px solid $color-primary-teal;
        padding-bottom: 8px;
      }
    }
    &:hover {
      text-decoration: none;
      span {
        border-bottom: 2px solid $color-primary-teal;
        padding-bottom: 8px;
      }
    }
  }
}
.DesktopSelectedMenuContainer {
  background-color: white;
  @include mq($breakpoint-desktop) {
    border-top: 1px solid $color-grey-light;
  }

}
.SelectedMenuWrap {
  padding: 0 16px;
  background-color: white;
  button {
    width: 100%;
  }
  &.Mobile {
    @include mq($breakpoint-desktop) {
      display: none;
    }
  }
  &.Desktop {
    display: none;
    @include mq($breakpoint-desktop) {
      display: grid;
      column-gap: 24px;
      max-width: 1248px;
      margin: 0 auto 32px;
      padding: 0 32px;
      grid-template-columns: repeat(5, 1fr);
    }
     @include mq($breakpoint-desktopMedium) {
padding: 0;
     }
    @include mq($breakpoint-desktopLarge) {
      max-width: 1560px;
     
    }
  }
}
.SubNavItem {
  display: block;
  width: 100%;
  font-size: 18px;

  &.HeaderItem {
    font-weight: 700;
    letter-spacing: 0.72px;
  }
  &.LabelItem {
    pointer-events: none;
  }
  &.SectionItem:hover {
    text-decoration: underline;
    color: $color-primary-teal;
  }
  &.LinkItem:hover {
    text-decoration: underline;
    color: $color-primary-teal;
  }
  @include mq($breakpoint-desktop) {
    color: $color-grey-medium;
    &.HeaderItem {
      color: black;
      border-bottom: 1px solid black;
      min-height: 65px;
      display: flex;
      align-items: center;
    }
  }
}

.SubNavMenuContainer {
  padding: 16px 0;
  border-top: 1px solid $color-grey-light;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include mq($breakpoint-desktop) {
    border-top: none;
    width: 100%;
    padding-top: 8px;
  }
}
.SubNavMenuHeading {
  padding: 16px 0;
  width: 100%;
  display: flex;
  text-align: left;
  align-items: center;
  column-gap: 16px;
  border-top: 1px solid $color-grey-light;
}
.SelectedMenuTitle {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.96px;
  color: $color-primary-teal;
  text-transform: capitalize;
}
.Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -100;
  background-color: black;
  opacity: 0;
  &.ActiveOverlay {
    z-index: 5;
    opacity: 0.5;
  }
}

.RatingWrap {
  display: none;
  @include mq($breakpoint-desktop) {
    column-gap: 20px;
    display: flex;
    align-items: center;
    padding: 8px 0 14px;
  }
}
.RatingItemIconWrap {
  display: none;
  position: relative;
  transition: .3s ease-in-out;
  @include mq($breakpoint-desktop) {
    display: block;
    width: auto;
    height: 56px;
  }

  @each $name, $value in $color-rating-list {
    &.Rating-#{$name} {
      &::after {
        content: '';
        border-bottom: 2px solid $value;
        top: 68px;
        width: 100%;
        position: absolute;
        left: 0;
        opacity: 0;
        display: inline-block;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }
  &.ActiveRatingItemIconWrap {
    &::after {
      opacity: 1;
    }
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}
.RatingItemIcon {
  width: 100% !important;
  height: 100% !important;
  svg {
    height: 100% !important;
    width: auto !important;
  }
}
.FeaturedItems {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 24px 0;
  grid-column-start: 5;
  justify-self: end;
}
.FeaturedItemWrap {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9px;
  overflow: hidden;
  color: white;
  width: 195px;
  height: 125px;
  display: flex;
  justify-content: center;
  padding: 0 12px;
  flex-direction: column;
  row-gap: 6px;
  position: relative;
  background-color: $color-primary-teal;
  box-shadow: 0 0 58px 0 rgba(55, 74, 82, 0.3);
  transition: all 0.3 ease-in-out;

  &.FeaturedItemWrapWithOverlay {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: black;
      opacity: 0.35;
      z-index: 0;
    }
  }
  h4 {
    font-size: 20px;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }
  span {
    text-decoration: underline;
    font-size: 16px;
    position: relative;
    z-index: 1;
  }
  &:hover {
    filter: brightness(1.2);
    h4,
    span {
      color: white;
    }
  }
}
.SearchBar {
  border-radius: 9px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 2px solid $color-grey-light;
  &:hover {
    border-color: $color-primary-teal;
  }
  &.SearchBarDesktop {
    display: none;
  }
  &.SearchBarMobile {
    border: none;
  }
  @include mq($breakpoint-desktop) {
    &.SearchBarDesktop {
      width: 100%;
    }
    &.SearchBarMobile {
      display: none;
    }
  }
}
.SearchInput {
  border: none;
  width: 100%;
  padding: 10px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 40px;
}
.SearchInputIcon {
  background-color: $color-primary-teal;
  border-radius: 0;
  span {
    display: flex;
  }
  svg {
    fill: white !important;
  }
}
.SearchModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  padding-top: 20px;
}

.SearchModalContent {
  position: relative;
  z-index: 500;
  padding-top: 20px;
}
.AgeRatingCarousel {
  width: 33px;
  @include mq($breakpoint-desktop) {
    display: none;
  }

}
.MobileToggleSearchInputIcon {
  display: flex;
   @include mq($breakpoint-desktop) {
    display: none;
  }
}
 
  .SearchBarDesktop {
     display: none;
      @include mq($breakpoint-desktop) { 
        width: 70%;
        display: block;
        }
    }