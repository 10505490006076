
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Footer {
  background-color: $color-primary-grey;
}
.MainFooter {
  padding: 32px 24px;
  position: relative;
  z-index: 3;
  border-top: 5px solid $color-primary-teal;
  margin-top: 32px;
  a:hover {
    color: $color-primary-teal;
    transition: $duration-shortest;
  }
  @include mq($breakpoint-tabletLandscape) {
    padding: 20px 32px;
  }
  @include mq($breakpoint-desktop) {
    display: flex;
    column-gap: 100px;
    max-width: 1248px;
    margin: 0 auto;
    padding: 20px 32px;
    border-top: 0;
    margin-top: 0;
  }
  @include mq($breakpoint-desktopMedium) {
    padding: 20px 0;
  }
  @include mq($breakpoint-desktopLarge) {
    max-width: 1560px;
  }
}
.SubFooter {
  background-color: white;
  padding: 16px;

  @include mq($breakpoint-desktop) {
    padding: 12px;
  }
}
.SubFooterWrap {
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  max-width: 375px;
  margin: 0 auto;
  @include mq($breakpoint-desktop) {
    max-width: 100%;
  }
}
.Link {
  font-family: $font-body;
  size: 16px;
  line-height: 40px;
  letter-spacing: 0.04;
  color: white;
  display: block;
  &.UnderscoreLink {
    text-decoration: underline;
  }
}
.BBFCLogo {
  max-width: 218px;
  margin: 0 auto;
  img: {
    width: 100%;
  }
  @include mq($breakpoint-desktop) {
    margin: 0;
  }
}
.SocialMediaList {
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  margin: 0 auto;
  column-gap: 10px;
}
.SocialMediaItem {
  &:hover {
    opacity: 0.5;
  }
}
.LogoCol {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.SubFooterLink {
  color: $color-primary-grey;
  font-size: 16px;
  font-family: $font-body;
  text-align: center;
  border-right: 1px solid $color-primary-grey;
  padding-right: 10px;
  line-height: 1;
  transition: all 0.3s ease-in-out;

  &:last-child {
    border-right: 0;
  }
  &:hover {
    color: $color-primary-teal;
    text-decoration: underline;
  }
}
.DesktopFooter {
  display: none;
  @include mq($breakpoint-desktop) {
    display: flex;
  }
}

.MobileFooter {
  display: flex;

  @include mq($breakpoint-desktop) {
    display: none;
  }
}
.LinkLists {
  display: flex;
  margin: 32px auto;
  column-gap: 64px;
  justify-content: center;
  @include mq($breakpoint-desktop) {
    justify-content: flex-start;
    margin: 0;
    column-gap: 100px;
  }
}
.MobileToTopBtn {
  color: $color-primary-white;
  font-family: $font-body;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.72px;
  padding: 16px 30px;
  border-radius: 3px;
  border: 1px solid white;
  margin: 32px auto 0;
  text-align: center;
  display: block;
  @include mq($breakpoint-desktop) {
    display: none;
  }
}
.DesktopToTopBtn {
  color: $color-primary-white;
  max-width: 79px;
  margin-left: auto;
  height: 72px;
  border-radius: 15px;
  display: none;
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease-in-out;
  @include mq($breakpoint-desktop) {
    display: inline-flex;
  }
  &:hover {
    opacity: 0.5;
  }
}
