
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';
.SearchV2 {
  width: 100%;
  .List {
    border-radius: 8px;
    overflow: hidden;
  }
  &.DesktopStyle {
    position: relative;
  }
}
.ModalStyle {
  z-index: 500;
}
.SearchField {
  border: 1px solid $color-primary-grey;
}

.SearchButton {
  border: 1px solid $color-primary-grey;
  border-left: none;
  color: $color-primary-white;
  background-color: $color-primary-teal;
}

.SearchSuggestionsWrapper {
  border: 1px solid $color-primary-grey;
  top: 43px;
  border-radius: 8px;
  &.SearchSuggestionsWrapperV2 {
    position: fixed;
    margin-top: 30px;
    z-index: 100;
    top: 135px;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);

    @include mq($breakpoint-desktop) {
      border: none;
      border-radius: 8px;
      top: 54px;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
    }

    &.SearchSuggestionDesktopStyle {
      width: 100%;
      @include mq($breakpoint-desktop) {
        max-width: 1280px;
      }
      @include mq($breakpoint-desktopLarge) {
         max-width: 1560px;
      }
    }
  }

  @include mq($breakpoint-desktopMedium) {
    top: 54px;
  }

  @include mq($breakpoint-desktopLarge) {
    top: 81px;
  }

}

.SearchFieldWrapper {
  height: spacing(6);

  @include mq($breakpoint-desktopMedium) {
    height: spacing(8);
  }

  @include mq($breakpoint-desktopLarge) {
    height: spacing(11);
  }
}
.SearchBar {
  border-radius: 9px;
  z-index: 50;
  position: relative;
  overflow: hidden;
  align-items: center;
  border: 2px solid $color-grey-light;
  width: 90%;
  margin: 20px auto;
  background-color: white;
  display: flex;

  @include mq($breakpoint-desktop) {
    width: 60%;
    margin: 20px auto;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}
.SearchInput {
  border: none;
  display: block;
  width: 100%;
  padding: 10px 20px;
  position: relative;
}
.SearchInputIcon {
  background-color: $color-primary-teal;
  border-radius: 0;
  svg {
    fill: white !important;
  }
}


.ListLabel {
  font-size: 20px;
}

.DesktopStyle {
  position: relative;
  margin: 0 auto;
  display: none;
  @include mq($breakpoint-desktop) {
    display: flex;
  }
}
