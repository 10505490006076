
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Main {
  flex: 1 0 auto;
  min-height: 500px;
  &.BBFC {
    padding-top: 72px;
    @include mq($breakpoint-desktop) {
      padding-top: 147px;
    }
  }
}
